<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ธนาคาร', 'บัญชีธนาคาร']" />
    <bank-account></bank-account>
  </div>
</template>

<script>
import BankAccount from "@/components/BankAccount.vue";
export default {
  components: { BankAccount },
}
</script>