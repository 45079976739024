<template>
  <div>
    <router-link class="btn btn-success mb-2" to="/bank/account/add" v-show="canEdit">เพิ่มบัญชีธนาคาร</router-link>
    <CDataTable :items="items" :fields="fields" :column-filter="{ external: true }"
      :items-per-page-select="{ label: 'จำนวนแถว' }" :table-filter-value.sync="tableFilterValue"
      :column-filter-value.sync="columnFilterValue" :items-per-page.sync="itemsPerPage" :loading="tableLoading"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }" v-on:pagination-change="paginationChange" v-on:update:column-filter-value="columnFilter" hover pagination dark
      border addTableClasses="w-100">
      <template #showDetails="{ item }">
        <td>
          <div v-if="!['CALL_CENTER', 'CALL_CENTER_ADMIN'].includes(user.level)
            " class="d-flex">
            <button class="btn btn-success mr-1" @click="update(item, 'APPROVED')" :disabled="!canEdit">
              <i class="fas fa-check"></i>
            </button>
            <button class="btn btn-danger mr-1" @click="update(item, 'REJECT')" :disabled="!canEdit">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </td>
      </template>
    </CDataTable>
    <CPagination :active-page="activePage" :double-arrows="false" :pages="totalPage" :dots="false"
      v-on:update:activePage="updateActivePage" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          sorter: false,
        },
        {
          key: "userId",
          label: "#สมาชิก",
          sorter: false,
          filter: this.$route.params.userId ? false : true,
        },
        {
          key: "accountName",
          label: "ชื่อบัญชี",
          sorter: false,
        },
        {
          key: "accountNumber",
          label: "เลขบัญชี",
          sorter: false,
        },
        {
          key: "bankNameTH",
          label: "ธนาคาร",
          filter: false,
          sorter: false,
        },
        {
          key: "userApproveId",
          label: "คนอนุมัติ",
          filter: false,
          sorter: false,
        },
        {
          key: "createdAt",
          label: "เวลา",
          filter: false,
          sorter: false,
        },
        {
          key: "status",
          label: "สถานะ",
          filter: false,
          sorter: false,
        },
        {
          key: "showDetails",
          label: "",
          filter: false,
          sorter: false,
        },
      ],
      items: [],
      tableFilterValue: "",
      columnFilterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("bank_account_edit")
    }
  },
  methods: {
    async update(item, status) {
      if (item.status == status) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "bank/account",
          data: {
            id: item.id,
            status: status,
          },
        })

        await this.$swal({
          icon: "success",
          title: "สำเร็จ",
        })

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    getClasses(status) {
      let classes = "text-positive"
      if (status == "CLOSE" || status == "REJECT") {
        classes = "text-negative"
      } else if (status == "PENDING") {
        classes = "text-info"
      }
      return classes
    },
    paginationChange(val) {
      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.items &&
        this.items.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
    loadData() {
      this.tableLoading = true

      if (this.$route.params.userId) {
        this.columnFilterValue.userId = "=" + this.$route.params.userId
      }


      this.axios({
        method: "get",
        url: "bank/account",
        params: {
          ...this.columnFilterValue,
          page: this.activePage,
          pageSize: this.itemsPerPage,
        },
      })
        .then((res) => {
          let data = res.data.data
          data.data?.forEach((item) => {
            item._cellClasses = {
              status: this.getClasses(item.status),
            }
            item.createdAt = this.$date(item.createdAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          })
          // this.totalPage = data.totalPage;
          this.items = data.data
          this.tableLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>